<template>
    <div class="home">
      <div style="
            height: 20px;
            line-height: 60px;
            right: 42px;
    position: fixed;
            text-align: right;">
            <i @click="handleSelect('/headCategory/headCategory')" class="el-icon-close"></i>
      </div>

      <div class="pt20"></div>
    <div class="form">
      <h2>编辑头像分类</h2>
      <el-form ref="form" :required="true" :model="form" label-width="180px">
          <el-form-item :required="true" label="分类中文名">
            <el-input v-model="form.category_name"></el-input>
          </el-form-item>
          <el-form-item :required="true" label="分类英文名">
            <el-input v-model="form.en_category_name"></el-input>
          </el-form-item>
          <el-form-item :required="true" label="排序">
            <el-input v-model="form.sort"></el-input>
          </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">确定</el-button>
          <el-button @click="goNav('/headCategory/headCategory')" >取消</el-button>
        </el-form-item>
      </el-form>
      
    </div>
      <div class="pt20"></div>
        
      
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'
export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      form: {
          id:-1,
          category_name:'',
          en_category_name:'',
          sort: '',
        },
        limit:1,
        options:[],
        fileList: [],
        obj:{},
      
    }
  },
  mounted() {
    const obj = this.$route.params.obj;
    if (obj) {
        let { id, category_name, en_category_name, sort } = obj;
        this.form = { id, category_name, en_category_name, sort };
    }
  },
  methods: {
    handleSelect(url){
      this.$router.push(url)
    },
     onSubmit() {
      let _this = this;
      let postdata = this.form;
      axios.post(config.headCategory,postdata)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: '编辑成功',
                type: 'success'
              });
              _this.goNav('/headCategory/headCategory');
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
      goNav(url){
        this.$router.push(url)
      },
  },
  created(){
      
  }
}
</script>
<style scoped>
.form{
    width: 500px;
    margin-left: 200px;
}
.el-icon-close:before{
  font-size: 24px;
}
</style>
